@import "../../styles/_var";

.userPage {
  height: fit-content;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  // gap: 96px;
  // padding: 96px 108px;
  padding: 96px $padding108;
  margin-top: 20px;
  @media (max-width: 768px) {
    padding: 40px 32px;
    margin-top: 100px;
  }

  .header {
    h1 {
      font-size: 44px;
      font-family: Inter Tight;
      font-weight: 600;
      line-height: 115%;
      color: $gray900;
      margin: 0;
      text-align: left;
      width: 100%;
      margin-bottom: 40px;
      @media (max-width: 450px) {
        /* Mobile/H3 */
        font-family: Inter;
        font-size: 34px;
        font-style: normal;
        font-weight: 300;
        line-height: 115%; /* 29.9px */
      }
    }
    p {
      color: var(--Gray-700, #484848);
      /* Desktop/Body Medium */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .sorting {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      .sorting__nav {
        display: flex;
        margin-top: 40px;
        flex-direction: row;
      }
      span {
        color: var(--Gray-900, #1a1a1a);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
      button {
        //  color: var(--Primary-500, #3c7d46);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        //font-weight: 500;
        font-weight: 200;
        line-height: 20px; /* 142.857% */
        background: none;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .checked {
        //background-color: red;
        color: var(--Primary-500, #3c7d46);
        font-weight: 600;
      }
    }
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    //position: relative;
    margin-top: 20px;
    h5 {
      width: 100%;
    }
    .otherCars {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      padding-left: 10px;
      justify-content: space-between;
      gap: 60px;
      overflow-x: scroll;
      &:active {
        cursor: grabbing;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: $white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray100;
        border-radius: 20px;
        border: 6px solid $white;
      }

      * {
        // max-width: 300px;
        //min-width: 280px;
        // width: 280px;
      }
      .littleCard {
        cursor: pointer;
      }
    }
  }
  .icon {
    width: 12px;
  }
}
.noCars {
  // border: black 1px solid;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  .noCars__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    a {
      color: $primary500;
      text-decoration: none;
    }
    h4 {
      font-weight: 500;
    }
  }
}
.specialOffers__corousel {
  margin-top: 40px;
  // margin-bottom: 48px;
  // background-color: orange;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  a {
    text-decoration: none;
  }
}
