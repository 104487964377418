@import "../../styles/_var";

.popupdel {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   bottom: 355px;
  top: 110px;
  left: 50%;
  transform: translate(-50%);
  width: 50%;
  height: 89vh;
  z-index: 9995;
  background-color: rgba(68, 68, 68, 0.8);
  border-radius: 8px;
  gap: 30px;
  @media (max-width: 1440px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }

  input {
    width: 50%;
    margin: 0 auto;
    height: 40px;
  }
  @media (max-width: 450px) {
    width: 90%;
    gap: 20px;
    height: 85svh;
    input {
      width: 80%;
      margin: 0 auto;
      height: 35px;
    }
  }
  h2 {
    color: $white;
    text-align: center;
    font-size: 32px;
    font-weight: 300;
    @media (max-width: 450px) {
      font-size: 24px;
    }
  }
  h3 {
    color: $primary100;
    font-size: 36px;
    font-weight: 100;
  }
  button {
    border-radius: 6px;
    width: 30%;
    height: 40px;
    @media (max-width: 450px) {
      width: 50%;
    }
  }
  .close__btn {
    // position: absolute;
    // top: 20px;
    // right: 20px;
    // width: 40px;
    // height: 40px;

    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 40px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    padding: 10px;
  }
}
