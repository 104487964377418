@import "./../../../styles/__var.scss";

.whyChooseUs {
  // background-color: aquamarine;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 96px 108px;
  gap: 24px;
  border-top: 2px solid $outline2;
  @media (max-width: 1200px) {
    padding: 40px 32px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
  }
  .whyChooseUs__image {
    width: 50%;
    height: 800px;
    border-radius: 16px;
    // border: black 1px solid;
    background-image: url("../../../assets/images/manAndWoman.webp");
    @media (max-width: 1200px) {
      height: 372px;
      background-image: url("../../../assets/images/manAndWoman-tablet.webp");
      background-repeat: no-repeat;
    }
    @media (max-width: 450px) {
      width: 100%;
      background-image: url("../../../assets/images/manAndWoman-mobile.webp");
      // background-size: contain;
    }
  }
  .whyChooseUs__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    padding: 0px 104px;
    gap: 48px;
    width: 50%;
    //height: 632px;
    // border: black 1px solid;
    @media (max-width: 1200px) {
      padding: 0;
      width: fit-content;
      width: 50%;
    }
    @media (max-width: 450px) {
      width: 100%;
    }

    .whyChooseUs__topContent {
      // border: black 1px solid;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 392px;
      // height: 332px;
      height: fit-content;
      @media (max-width: 1200px) {
        width: 100%;
      }
      h2 {
        color: $gray900;
        font-family: "Inter Tight";
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 115%;
        margin: 0;
        @media (max-width: 1200px) {
          font-size: 30px;
        }
      }
      span {
        color: $gray900;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        p {
        }
      }
    }
    .whyChooseUs__bottomContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;

      width: 392px;
      // height: 252px;
      //  border: black 1px solid;
      @media (max-width: 1200px) {
        gap: 16px;
        width: 100%;
      }
      div {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
      }
      //   .bottomContent__first {
      //     border: black 1px solid;
      //   }
      //   .bottomContent__second {
      //     border: black 1px solid;
      //   }
      //   .bottomContent__third {
      //     border: black 1px solid;
      //   }
      .bottomContent__left {
        // border: black 1px solid;
        hr {
          //   width: 100%;
          width: 16px;
          border: $secondary500 1px solid;
        }
      }
      .bottomContent__right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        //  border: black 1px solid;

        span {
          // background-color: antiquewhite;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $gray900;
        }
        p {
          margin: 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $gray900;
        }
      }
    }
  }
}
