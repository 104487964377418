@import "../../../styles/_var";

.likedCard {
  width: 600px;
  height: 460px;
  border: $gray300 1px solid;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  color: $gray500;
  max-width: 600px;
  width: 100%;
  @media (max-width: 1000px) {
    max-width: 100%;
  }
  @media (max-width: 450px) {
    width: 100%;
    height: fit-content;
  }

  .top {
    width: 100%;
    height: 70%;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  .bottom {
    display: flex;
    height: 30%;
    flex-direction: column;
    padding: 16px;
    justify-content: space-between;
    @media (max-width: 450px) {
      gap: 8px;
    }
    .brandModelYear {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      color: $gray700;
      h5 {
        font-size: 18px;
        font-weight: 600;
      }
      h6 {
        font-size: 18px;
        font-weight: 500;
        color: $gray500;
        //  font-family: Inter;
      }
    }
    .ownersFuelMilleage {
      display: flex;
      flex-direction: row;
      gap: 5px;
      color: $gray500;
      font-size: 16px;
    }

    .price {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $primary500;

      font-size: 18px;
      font-family: Inter Tight;
      font-style: normal;
      font-weight: 600;
      line-height: 115%;
    }
  }
}
