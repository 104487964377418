@import "../../../styles/_var";
.selectedFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 5px;
  border-radius: 8px;
  background-color: #f0f0f0;
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $gray900;
    cursor: default;
  }
  button {
    // background-color: red;
  }
}
