@import "../../../styles/_var";

.sorted {
  // border: black 1px solid;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .sorted__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    // border: black 1px solid;
    .total__found {
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $gray700;
        margin: 0;
      }
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray700;
        margin: 0;
      }
    }
    .sortBy {
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      p {
        margin: 0;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray700;
      }
      span {
        margin: 0;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray900;
      }
      .sortBy__item {
        // border: $gray500 1px solid;
        border-radius: 5px;
        padding: 2px 10px;
        cursor: pointer;
        &:hover {
          background-color: $primary500;
          color: $white;
          opacity: 1;
          // display: block;
          .hidden {
            //display: block;

            // background-color: aqua;
          }
        }
      }
      .hidden {
        opacity: 0.5;
        //display: none;
      }
      .selected {
        background-color: $primary500;
        color: $white;
      }
    }
  }
  .filters__btn {
    // width: 150px;
    display: none;
    @media (max-width: 1200px) {
      width: fit-content;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: $gray900;
      padding: 6px 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 8px;
      gap: 8px;
    }
  }
  .sorted__bottom {
    //border: black 1px solid;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0px;
    gap: 4px;
    // span {
    //   // background-color: aquamarine;
    // }
  }
}
.sortselect {
  &__control {
    background-color: red !important;
  }
  &--menu-is-open {
    border-color: green !important;
  }
}
.sortselect__control {
  background-color: red !important;
  min-height: 20px !important;
}
