.popUp__errors {
  position: fixed;
  //   bottom: 355px;
  top: 110px;
  left: 50%;
  transform: translate(-50%);
  border-radius: 10px;
  height: 89vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: black 1px solid;
  z-index: 9995;
  background-color: rgba(119, 119, 119, 0.6);
  background-color: rgba(68, 68, 68, 0.8);
  // opacity: 0.5;
  @media (max-width: 450px) {
    width: 90%;
    gap: 20px;
    height: 85svh;
  }
  .container {
    //position: absolute;
    // top: 50%;
    // right: 50%;
    // transform: translate(-50%, -50%);
    border-radius: 10px;
    // background-color: red;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .close__btn {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 40px;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 8px;
      padding: 10px;
    }
    .message_box {
      //background-color: red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-size: 30px;
        color: aliceblue;
        text-align: center;
        @media (max-width: 450px) {
          font-size: 24px;
          font-weight: 300;
          width: 90%;
        }
      }
      .agree__btn {
        margin-top: 30px;
        width: 70%;
        height: 50px;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }
}
