@import "./../../../styles/__var.scss";

.specialOffers {
  position: relative;

  background-color: $white;
  z-index: 9;
  // border: black 1px solid;
  padding-bottom: 96px;
  padding: 170px $padding108 96px;
  @media (max-width: 1200px) {
    // padding: 140px 32px 0 32px;
    padding: 40px 32px;
    margin-top: 100px;
  }
  @media (max-width: 768px) {
    margin-top: 0;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .specialOffers__content {
    .specialOffers__header {
      //  border: black 1px solid;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      //   align-items: baseline;
      align-items: center;
      padding: 0px;
      gap: 12px;
      h2 {
        font-family: "Inter Tight";
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 115%;
        color: $gray900;
        margin: 0;
        @media (max-width: 1200px) {
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 115%; /* 34.5px */
        }
      }
      .specialOffers__navigation {
        //    border: black 1px solid;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 40px;
        .nav__button {
          background-color: none;
          background: none;
        }
        @media (max-width: 450px) {
          display: none;
        }
      }
    }
  }
  .specialOffers__corousel {
    margin-top: 48px;
    margin-bottom: 48px;
    // background-color: orange;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
  }
  .specialOffers__navigation_mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (min-width: 450px) {
      display: none;
    }
    .navigation_mobile_btns {
      display: flex;
      flex-direction: row;
      gap: 20px;
      button {
        background: none;
      }
    }
  }
}
