@import "../../styles/_var";

.login {
  //   background-color: red;
  min-height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;

  @media (max-width: 768px) {
    padding: 32px;
  }
  @media (max-width: 450px) {
    padding: 32px 16px;
  }
  .wrapper {
    // background-color: rgb(201, 201, 212);
    width: 390px;
    // height: 500px;
    margin: 48px 0;
    @media (max-width: 768px) {
      width: 60%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }

    h1 {
      color: $gray900;

      /* Desktop/H3 */
      font-family: Inter Tight;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 115%; /* 36.8px */
      margin: 0;
    }
  }
  .form {
    width: 100%;
    // height: 100%;
    // border: black 1px solid;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .email {
      //   border: black 1px solid;
      display: flex;
      flex-direction: column;
      color: var(--gray-900, #1a1a1a);
      /* Desktop/Body Small */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      gap: 8px;
      input {
        display: flex;
        padding: 14px 12px;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid $outline1;
        color: $gray300;
        /* Desktop/Body Small */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        &:focus {
          outline: none;
        }
      }
    }
    .password {
      display: flex;
      flex-direction: column;
      //   border: black 1px solid;
      gap: 8px;
      input {
        display: flex;
        padding: 14px 12px;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid $outline1;
        color: $gray300;
        /* Desktop/Body Small */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        &:focus {
          outline: none;
        }
      }

      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      span {
        color: $gray900;
        /* Desktop/Body Small */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      span:last-child {
        color: $primary500;

        /* Desktop/Body Small */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
      }
    }
    .loginBtn {
      background-color: $primary500;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      padding: 12px 24px;
      display: flex;

      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
    }
    .signUp {
      color: var(--gray-900, #1a1a1a);
      text-align: center;
      /* Desktop/Body Small */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      span {
        color: var(--primary-500, #3c7d46);
        /* Desktop/Label 2 */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;
        margin-left: 5px;
      }
    }
    .line {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: var(--gray-500, #767676);
      /* Desktop/Body Small */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      hr {
        border: $outline2 1px solid;
        width: 47%;
      }
    }
    .googleBtn {
      border: $outline2 1px solid;
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      color: var(--gray-900, #1a1a1a);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 171.429% */
    }
  }
  .navLink {
    text-decoration: none;
  }
}
