@import "./../../../styles/__var.scss";

.brandCard {
  display: flex;
  flex-direction: column;
  // flex: 1;
  // align-items: flex-start;
  padding: 0px;

  width: 184px;
  height: 144px;
  box-shadow: 0px 1px 2px rgba(28, 30, 34, 0.08),
    0px 8px 16px rgba(28, 30, 34, 0.08);
  border-radius: 8px;

  align-items: center;
  background-color: $white;
  // background-color: aquamarine;
  cursor: pointer;
  @media (max-width: 1200px) {
    width: auto;
  }

  .brandCard_content {
    //  border: black 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 12px;
    img {
      // border: black 1px solid;
      height: 76px;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $gray900;
    }
  }
}
