@import "./../../../styles/__var.scss";

.showAllBrands {
  // border: black 1px solid;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $primary500;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;
  width: fit-content;
  cursor: pointer;
}
