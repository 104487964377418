@import "./../../../styles/__var.scss";

.mediumCard {
  // height: 170px;
  width: 380px;
  // max-height: 119px;
  height: 115px;
  // background-color: antiquewhite;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: $gray900;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  // border: black 1px solid;
  @media (max-width: 450px) {
    width: 100%;
    flex-direction: column;
    height: fit-content;
  }
  .mediumCard__img {
    // background-color: red;
    width: 50%;
    min-height: 100%;
    // height: 120px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    @media (max-width: 450px) {
      width: 100%;

      border-top-right-radius: 8px;
      border-bottom-left-radius: 0;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      @media (max-width: 450px) {
      }
    }
  }

  .information {
    // background-color: aquamarine;
    width: 50%;
    height: 100%;
    //    height: 118px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: $gray100 1px solid;
    border-left: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    @media (max-width: 450px) {
      width: 100%;
      border-top-right-radius: 0;
      border-bottom-left-radius: 8px;
      border-left: $gray100 1px solid;
      border-top: none;
    }
    .brandModel {
      display: flex;
      flex-direction: row;
      gap: 5px;
      font-weight: 600;
      //  border: black 1px solid;
    }
    .ownersYearMileage {
      display: flex;
      flex-direction: row;
      gap: 5px;
      color: $gray500;
      font-size: 12px;
      // border: black 1px solid;
    }
    .prices {
      display: flex;
      flex-direction: row;
      font-weight: 600;
      //  border: black 1px solid;
    }
  }
}
