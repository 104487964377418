@import "../../styles/_var";

.liked {
  width: 100%;
  height: fit-content;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  // gap: 96px;
  // padding: 96px 108px;
  padding: 96px $padding108;

  margin-top: 20px;
  // border: black 1px solid;
  @media (max-width: 450px) {
    padding: 40px 32px;
    margin-top: 100px;
  }
  .header {
    h1 {
      font-size: 44px;
      font-family: Inter Tight;
      font-weight: 600;
      line-height: 115%;
      color: $gray900;
      margin: 0;
      text-align: left;
      width: 100%;
      margin-bottom: 40px;
      @media (max-width: 450px) {
        /* Mobile/H3 */
        font-family: Inter;
        font-size: 34px;
        font-style: normal;
        font-weight: 300;
        line-height: 115%; /* 29.9px */
      }
    }
    p {
      color: var(--Gray-700, #484848);
      /* Desktop/Body Medium */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .sorting {
      margin-top: 40px;
      display: flex;
      flex-direction: row;

      span {
        color: var(--Gray-900, #1a1a1a);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
      button {
        //  color: var(--Primary-500, #3c7d46);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        //font-weight: 500;
        font-weight: 200;
        line-height: 20px; /* 142.857% */
        background: none;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .checked {
        //background-color: red;
        color: var(--Primary-500, #3c7d46);
        font-weight: 600;
      }
    }
  }
  .wrapper {
    // display: flex;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // flex-direction: row;
    // flex-wrap: wrap;
    // flex-direction: row;
    // flex-wrap: wrap;
    gap: 20px;
    // justify-content: space-between;
    // align-items: center;
    // gap: 10px;
    margin-top: 20px;

    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column;
    }
    a {
      text-decoration: none;
    }
  }
  .icon {
    width: 12px;
  }
  .buttons {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    // padding-bottom: 15px;
    // border: black 1px solid;

    span {
      border: black 1px solid;
      border: 1px solid $outline1;
      border-radius: 8px;
      padding: 5px 10px;
      cursor: pointer;
    }
    .currentPage {
      background-color: $primary500;
      color: $white;
      border: 1px solid $primary500;
    }
    button {
      border: black 1px solid;
      border: 1px solid $outline1;
      border-radius: 8px;
    }
  }
  .emptyPage {
    // border: black 1px solid;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      max-width: 300px;
    }
    a {
      color: $primary500;
      text-decoration: none;
    }
  }
}
