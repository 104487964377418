@import "./../../../styles/__var.scss";

.header {
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px $padding108;
  border-bottom: 1px solid $outline2;
  position: fixed;
  top: 30px;
  top: 40px;
  z-index: 80;
  max-width: 1440px;
  width: 100%;
  @media (max-width: 1200px) {
    z-index: 90;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .links {
    display: flex;
    flex-direction: row;
    gap: 32px;
    a {
      text-decoration: none;
      color: $gray900;
      transition: 0.2s color ease-in-out;
    }
    @media (hover: hover) {
      a:hover {
        color: $primary500;
      }
    }
  }
  .menu {
    display: none;
  }
  @media (max-width: 1200px) {
    display: flex;
    padding: 12px 32px;
    justify-content: space-between;
    align-items: center;
    .links {
      display: none;
    }
    .menu {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  .header__nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    gap: 40px;

    li {
      list-style: none;
      color: $gray900;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-decoration: none;
    }
    .navLink {
      list-style: none;
      color: $gray900;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-decoration: none;
    }
  }
  .header__formGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 40px;
    @media (max-width: 1200px) {
      display: none;
    }

    .formGroup__icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0px;
      gap: 16px;
      // @media (max-width: 450px) {
      //   div:nth-child(2) {
      //     display: none;
      //   }
      //   div:nth-child(3) {
      //     display: none;
      //   }
      // }

      .searchBox {
        position: relative;
        button {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 20px;
          height: 20px;

          background: none;
          border: #9b9b9b 1px solid;
          border-radius: 5px;

          padding: 0;
          transition: border 0.2s ease-in-out;
          &:hover {
            border: $gray700 1px solid;
          }
        }
        input {
          height: 20px;
        }
      }
    }
  }
  .header__formGroup__mobile {
    display: none;
    .searchBox {
      position: relative;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $white;
      width: 100%;
      height: 65px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        width: 30px;
        height: 30px;

        background: none;
        // border: #9b9b9b 1px solid;
        border-radius: 5px;

        padding: 0;
        transition: border 0.2s ease-in-out;
        &:hover {
          border: $gray700 1px solid;
        }
      }
      input {
        height: 60%;
        width: 80%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    @media (max-width: 1200px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }
}
.user {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  p {
    color: $gray900;
    font-family: "Inter";
    font-style: normal;
    // font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  button {
    background-color: $primary500;
    border-radius: 8px;
    padding: 2px 12px;
    color: $white;
    font-family: "Inter";
    font-style: normal;
    transition: 0.2s background-color ease-in-out;
  }
  @media (hover: hover) {
    button {
      &:hover {
        background-color: $red500;
      }
    }
  }
}
