@import "./../../../styles/__var.scss";

.browseByBrand {
  // background-color: rgb(58, 162, 131);
  background-color: $lightBG;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  // padding: 96px 108px;
  // gap: 48px;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  // height: 464px;
  .browseByBrand__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 96px 108px;
    gap: 48px;
    @media (max-width: 1200px) {
      padding: 40px 32px;
      width: 100%;
    }
    h2 {
      // border: black 1px solid;
      font-family: "Inter Tight";
      font-style: normal;
      font-weight: 600;
      font-size: 38px;
      line-height: 115%;
      margin: 0;
      color: $gray900;
      @media (max-width: 1200px) {
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 115%; /* 34.5px */
      }
    }
    .browseByBrand__favoritesBrands {
      //  border: black 1px solid;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;

      @media (max-width: 1200px) {
        width: 100%;
      }
      .browseByBrand__row {
        // border: black 1px solid;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        @media (max-width: 1200px) {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 16px;
        }
        @media (max-width: 450px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      .allBrands {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
        @media (max-width: 768px) {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 16px;
        }
        @media (max-width: 450px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
