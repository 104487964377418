@import "__var.scss";

.app {
  //background-color: rgb(164, 227, 164);
  background-color: $white;
  max-width: 1440px;
  margin: 0 auto;
  //  padding-left: 108px;
  // padding-right: 108px;
  height: fit-content;
  position: relative;
}

.header {
  background-color: #ffffff;
}
