@import "../../styles/_var";
.catalog {
  //  background-color: rgb(237, 202, 202);
  // width: 100%;
  height: fit-content;
  min-height: 1200px;
  // height: 2000px;
  position: relative;
  padding: 0 212px;
  // width: 200px;
  margin-top: 120px;
  // position: -webkit-sticky;
  // position: sticky;
  // top: 80px;
  // border-top: 1px solid $outline2;
  @media (max-width: 1200px) {
    padding: 0 32px;
  }
  @media (max-width: 450px) {
    padding: 2px 16px;
    margin-top: 100px;
  }
  h3 {
    font-family: "Inter Tight";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 115%;
    color: $gray900;
    margin: 0;
    margin-bottom: 30px;
    margin-top: 54px;
    // border: black 1px solid;
    @media (max-width: 450px) {
      /* Mobile/H3 */
      font-family: Inter;
      font-size: 34px;
      font-style: normal;
      font-weight: 300;
      line-height: 115%; /* 29.9px */
    }
  }
  .catalog__content {
    display: flex;
    flex-direction: row;
    gap: 24px;
    position: relative;
    // height: 1000px;
    @media (max-width: 1200px) {
      position: initial;
    }
    @media (max-width: 450px) {
      position: initial;
    }
    .catalog__left {
      display: flex;
      flex-direction: column;
      gap: 24px;
      min-width: 705px;
      @media (max-width: 768px) {
        min-width: 100%;
      }
    }
  }
}
.paginateBar {
  // background-color: red;
  display: flex;
  flex-direction: row;
  gap: 10px;
  // border: black 1px solid;
  justify-content: center;
  padding: 0;

  li {
    list-style: none;
    border: $outline2 1px solid;
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: $primary500;
      border: $primary500 1px solid;
      a {
        color: $white;
      }
    }
    a {
      text-decoration: none;
      font-size: 14px;
      font-family: Inter;
      line-height: 20px;
      color: $gray700;

      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }
  }
  :global {
    .active {
      background-color: $primary500;
      border: $primary500 1px solid;
      a {
        color: $white;
      }
    }
    .page-link {
      //  background-color: red;
    }
    .page-item-break {
      // background-color: aqua;
      // a {
      //   color: red;
      // }
      // background-color: aqua;
      border: none;
    }
  }
}

.catalogPagination {
  width: 100%;
  //border: black 1px solid;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  @media (max-width: 450px) {
    align-items: center;
  }
  a {
    text-decoration: none;
    width: 100%;
    &:hover {
      text-decoration: none;
    }
  }
}

.bigCrad__wrapper {
  position: relative;
  .delete_btn {
    background-color: $red500;
    border-radius: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 200;
    cursor: default;
    color: $white;
  }
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding-bottom: 15px;
  span {
    border: black 1px solid;
    border: 1px solid $outline1;
    border-radius: 8px;
    padding: 5px 10px;
    cursor: pointer;
  }
  .currentPage {
    background-color: $primary500;
    color: $white;
    border: 1px solid $primary500;
  }
  button {
    border: black 1px solid;
    border: 1px solid $outline1;
    border-radius: 8px;
  }
}
.noCars {
  // border: black 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    font-weight: 400;
  }
}
