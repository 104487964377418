@import "./../../../styles/__var.scss";
.getAquoteBig {
  // border: black 1px solid;
  display: flex;
  width: 1016px;
  padding: 40px;
  align-items: center;
  gap: 40px;
  border-radius: 16px;
  background: $white;
  box-shadow: 0px 8px 16px 0px rgba(28, 30, 34, 0.08),
    0px 1px 2px 0px rgba(28, 30, 34, 0.08);

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    gap: 16px;
  }
  .getAquoteBig__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    h5 {
      // border: black 1px solid;
      font-size: 22px;
      font-family: Inter Tight;
      font-weight: 600;
      line-height: 120%;
      color: $gray900;
      @media (max-width: 450px) {
        text-align: center;
      }
    }
    span {
      font-size: 16px;
      font-family: Inter;
      line-height: 24px;
      color: $gray900;
      @media (max-width: 450px) {
        text-align: center;
      }
    }
  }
}
