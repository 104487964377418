@import "../../styles/_var";

.fullWidthImg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(119, 119, 119, 0.9);
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    img {
      max-width: 100%;
    }
  }

  button {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: rgba(155, 155, 155, 0.6);
    color: $tertiary50;
    font-size: 16px;
    border: $gray100 1px solid;
    border-radius: 8px;
  }
}
