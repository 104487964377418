@import "../../../styles/_var";
.moreFilters {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  // border: black 1px solid;
  cursor: pointer;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $primary500;
    margin-right: 5px;
  }
}
