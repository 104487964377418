@import "../../styles/_var";

.details {
  //   background-color: aqua;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 $padding108;
  margin-top: 110px;
  @media (max-width: 1200px) {
    padding: 32px;
  }
  @media (max-width: 450px) {
    padding: 16px;
  }
  .topNavigation {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    a {
      text-decoration: none;
    }
    span {
      font-size: 14px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $gray500;
    }
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .details__main {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    gap: 24px;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
  .content {
    //border: black 1px solid;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: fit-content;
    width: 50vw;
    max-width: 808px;
    @media (max-width: 1200px) {
      max-width: 100%;
      width: 100%;
    }
    .content__pictures {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .bigPicture {
        // width: 808px;
        // height: 606px;

        // background-color: bisque;
        position: relative;

        img {
          width: 808px;
          width: 100%;
          border-radius: 8px;
        }
        .fullWidth {
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: rgba(155, 155, 155, 0.6);
          color: $tertiary50;
          font-size: 16px;
          border: $gray100 1px solid;
          border-radius: 8px;
        }
      }
      .littlePictures {
        height: 100px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        overflow-x: scroll;
        max-width: 808px;
        cursor: grab;
        //  border: black 1px solid;
        &:active {
          cursor: grabbing;
        }

        &::-webkit-scrollbar {
          width: 5px; /* ширина всей полосы прокрутки */
        }

        &::-webkit-scrollbar-track {
          background: $white; /* цвет зоны отслеживания */
        }

        &::-webkit-scrollbar-thumb {
          background-color: $gray100; /* цвет бегунка */
          border-radius: 20px; /* округлось бегунка */
          border: 6px solid $white; /* отступ вокруг бегунка */
        }

        @media (max-width: 450px) {
          overflow-x: scroll;
          height: fit-content;
        }
        .little_preview {
          background-color: blanchedalmond;
          border-radius: 8px;
          width: 94px;
          height: fit-content;
          @media (max-width: 450px) {
            height: 70px;
          }
          // height: 70px;
          cursor: pointer;
          img {
            width: 94px;
            border-radius: 8px;
          }
        }
      }

      // .swiper {
      //   border: black 1px solid;
      //   // background-color: red !important;
      //   height: 100px;

      //   img {
      //     //  margin-top: 10px;
      //     height: 95px;
      //   }
      //   .swiper-wrapper {
      //     height: 100px !important;
      //     //  background-color: red !important;
      //   }
      .detailsSwiper {
        // display: flex;
        // flex-direction: row;
        //  background-color: red !important;
        border: black 1px solid;
        img {
          height: 100px;
        }

        .swiper-wrapper {
          background-color: red !important;
          height: 200px;
        }
      }
      // }
    }
    .content__mainInformation {
      //  border: black 1px solid;
      display: flex;
      flex-direction: row;
      gap: 25px;
      //  background-color: aliceblue;
      height: fit-content;
      width: fit-content;
      max-width: 100%;
      @media (max-width: 1200px) {
        width: 100%;
        justify-content: space-between;
        gap: 0;
      }
      @media (max-width: 450px) {
        flex-direction: column;
      }
      .left__information {
        // background-color: rgb(0, 255, 98);
        display: flex;
        width: 392px;
        width: 50%s;
        //  height: 400px;
        flex-direction: column;
        align-items: flex-start;
        @media (max-width: 1200px) {
          width: 48%;
        }
        @media (max-width: 450px) {
          width: 100%;
        }
        div {
          display: flex;
          padding: 10px 0px;
          align-items: flex-start;
          gap: 12px;
          align-self: stretch;
          border-bottom: 1px solid $outline2;
          span {
            width: 200px;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $gray900;
          }
          span:first-child {
            font-weight: 600;
          }
        }
      }
      .right__information {
        // background-color: burlywood;
        display: flex;
        width: 392px;
        // height: 400px;
        flex-direction: column;
        align-items: flex-start;
        @media (max-width: 1200px) {
          width: 48%;
        }
        @media (max-width: 450px) {
          width: 100%;
        }

        div {
          display: flex;
          padding: 10px 0px;
          align-items: flex-start;
          gap: 12px;
          align-self: stretch;
          border-bottom: 1px solid $outline2;
          span {
            width: 140px;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $gray900;
            @media (max-width: 450px) {
              width: 200px;
            }
          }

          span:first-child {
            font-weight: 600;
          }
        }
      }
    }
    .content__description {
      height: 300px;
      height: fit-content;
      width: 808px;
      width: 100%;
      // background-color: aliceblue;
      display: flex;
      flex-direction: column;
      gap: 8px;
      @media (max-width: 1200px) {
        width: 100%;
      }
      h5 {
        font-size: 22px;
        font-family: Inter Tight;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        color: $gray900;
        // border: black 1px solid;
        margin-bottom: 16px;
      }
      .description {
        overflow: hidden;
        color: $gray900;
        text-overflow: ellipsis;
        // border: black 1px solid;
        font-size: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
      }
    }
    .content__features {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 24px;
      align-self: stretch;

      h5 {
        font-size: 22px;
        font-family: Inter Tight;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        color: $gray900;
      }
      .features {
        display: flex;
        flex-direction: row;
        gap: 24px;
        @media (max-width: 1200px) {
          width: 100%;
          justify-content: space-between;
        }
        @media (max-width: 450px) {
          flex-direction: column;
        }
        .features_left {
          // background-color: beige;
          width: 392px;
          @media (max-width: 1200px) {
            width: 48%;
          }
          @media (max-width: 450px) {
            width: 100%;
          }
          span {
            color: $gray900;
            /* Desktop/Label 1 */
            font-size: 16px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
          }
          ul {
            // border: black 1px solid;
            padding-left: 15px;
            margin-top: 6px;
          }
          li {
            // border: black 1px solid;
            list-style-image: url("../../assets/icons/checkMark.svg");
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            color: $gray900;
            vertical-align: middle;
          }
        }
        .features_right {
          width: 392px;

          @media (max-width: 1200px) {
            width: 48%;
          }
          @media (max-width: 450px) {
            width: 100%;
          }
          span {
            color: $gray900;
            /* Desktop/Label 1 */
            font-size: 16px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
          }
          ul {
            padding-left: 15px;
            margin-top: 6px;
          }
          li {
            list-style-image: url("../../assets/icons/checkMark.svg");
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            color: $gray900;
            vertical-align: middle;
          }
        }
      }
    }
    .content__quick_links {
      // border: black 1px solid;
      display: flex;
      flex-direction: column;
      gap: 24px;
      h5 {
        color: $gray900;

        font-size: 22px;
        font-family: Inter Tight;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }
      .links {
        //  border: black 1px solid;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 8px;
        height: 50px;
        @media (max-width: 450px) {
          height: fit-content;
        }
        a {
          color: $primary500;
          text-decoration: none;
          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
    .content__similar_cars {
      // background-color: aliceblue;
      // border: black 1px solid;
      display: flex;
      width: 100%;
      // padding: 96px 108px;
      flex-direction: column;
      // align-items: center;
      gap: 24px;
      padding-bottom: 20px;
      @media (max-width: 450px) {
        // gap: 50px;
      }
      h5 {
        color: $gray900;

        font-size: 22px;
        font-family: Inter Tight;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }
    }
  }
  .CTA {
    // border: black 1px solid;
    display: flex;
    width: 392px;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 8px;
    gap: 24px;
    @media (max-width: 1200px) {
      display: none;
    }

    .CTA__info {
      //  border: black 1px solid;
      display: flex;
      width: 392px;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid $outline2;
      border-radius: 8px;
      .info__top {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        .saveAndShare {
          // border: black 1px solid;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          span {
            // border: black 1px solid;
            display: flex;
            flex-direction: row;
            gap: 2px;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $gray500;
            i {
              //  border: black 1px solid;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }
          }
        }
        h5 {
          color: $gray900;
          font-size: 22px;
          font-family: Inter Tight;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
        }
        h4 {
          color: $primary500;

          font-size: 26px;
          font-family: Inter Tight;
          font-style: normal;
          font-weight: 600;
          line-height: 115%;
        }
        table {
          // background-color: antiquewhite;
          width: 100%;

          border-collapse: separate;
          border-spacing: 10px;
          tr {
            color: $gray900;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border: black 1px solid;

            td:first-child {
              font-weight: 600;
            }
          }
        }
      }
      .info__bottom {
        display: flex;
        height: 292px;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        background-color: $lightBG;
        gap: 8px;
        border-top: 1px solid $outline2;
        span {
          color: $gray900;
          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        span:first-child {
          font-weight: 600;
        }
        .checkAvail {
          display: flex;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;

          align-self: stretch;
          background-color: $primary500;
          border-radius: 8px;
          width: 100%;
          margin-top: 8px;
          color: $white;
          font-size: 16px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .contacts {
          width: 100%;
          button {
            width: 100%;
            border: $outline1 1px solid;
            padding: 14px 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $white;
          }
          button:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom: none;
          }
          button:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
    .CTA__inspection {
      //  border: black 1px solid;
      background-color: $secondary50;
      display: flex;
      width: 392px;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      border-radius: 8px;
      .inspection__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        h5 {
          font-size: 22px;
          font-family: Inter Tight;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          color: $gray900;
        }
        .inspected__label {
          color: #000;
          font-size: 12px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          background-color: $secondary500;
          border-radius: 8px;
          padding: 4px 8px;
          gap: 2px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          i {
            // border: black 1px solid;
            height: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }
        }
      }
      .inspection__report {
        // background-color: aliceblue;
        width: 100%;
        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 8px;

          tr {
            color: $gray900;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border: black 1px solid;

            td:first-child {
              font-weight: 600;
            }
          }
        }
        .download {
          margin-top: 12px;
          margin-left: 8px;

          display: flex;
          flex-direction: row;
          gap: 4px;
          i {
            // border: black 1px solid;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: fit-content;
          }
          span {
            // border: black 1px solid;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            color: $primary500;
          }
        }
      }
      span {
        color: $gray900;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      p {
        color: $gray900;

        font-size: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      button {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: $primary500;
        color: $white;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        width: 100%;
      }
      .full_inspection {
        // background-color: bisque;
        display: flex;
        flex-direction: column;
        gap: 12px;
        a {
          text-decoration: none;
          color: $primary500;
          text-align: center;

          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
    .CTA__added {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;
      // border: black 1px solid;
      width: 100%;
      span {
        color: $gray300;
      }
      b {
        color: $gray500;
      }
    }
  }
  .CTA__mobile {
    // border: black 1px solid;
    display: none;
    width: 392px;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 8px;
    gap: 24px;
    @media (max-width: 1200px) {
      display: flex;
    }

    .CTA__info {
      //  border: black 1px solid;
      display: flex;
      width: 392px;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid $outline2;
      border-radius: 8px;
      .info__top {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        .saveAndShare {
          // border: black 1px solid;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          span {
            // border: black 1px solid;
            display: flex;
            flex-direction: row;
            gap: 2px;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $gray500;
            i {
              //  border: black 1px solid;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }
          }
        }
        h5 {
          color: $gray900;
          font-size: 22px;
          font-family: Inter Tight;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
        }
        h4 {
          color: $primary500;

          font-size: 26px;
          font-family: Inter Tight;
          font-style: normal;
          font-weight: 600;
          line-height: 115%;
        }
        table {
          // background-color: antiquewhite;
          width: 100%;

          border-collapse: separate;
          border-spacing: 10px;
          tr {
            color: $gray900;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border: black 1px solid;

            td:first-child {
              font-weight: 600;
            }
          }
        }
      }
      .info__bottom {
        display: flex;
        height: 292px;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        background-color: $lightBG;
        gap: 8px;
        border-top: 1px solid $outline2;
        span {
          color: $gray900;
          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        span:first-child {
          font-weight: 600;
        }
        .checkAvail {
          display: flex;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;

          align-self: stretch;
          background-color: $primary500;
          border-radius: 8px;
          width: 100%;
          margin-top: 8px;
          color: $white;
          font-size: 16px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .contacts {
          width: 100%;
          button {
            width: 100%;
            border: $outline1 1px solid;
            padding: 14px 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $white;
          }
          button:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom: none;
          }
          button:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
    .CTA__inspection {
      //  border: black 1px solid;
      background-color: $secondary50;
      display: flex;
      width: 392px;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      border-radius: 8px;
      .inspection__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        h5 {
          font-size: 22px;
          font-family: Inter Tight;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          color: $gray900;
        }
        .inspected__label {
          color: #000;
          font-size: 12px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          background-color: $secondary500;
          border-radius: 8px;
          padding: 4px 8px;
          gap: 2px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          i {
            // border: black 1px solid;
            height: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }
        }
      }
      .inspection__report {
        // background-color: aliceblue;
        width: 100%;
        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 8px;

          tr {
            color: $gray900;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border: black 1px solid;

            td:first-child {
              font-weight: 600;
            }
          }
        }
        .download {
          margin-top: 12px;
          margin-left: 8px;

          display: flex;
          flex-direction: row;
          gap: 4px;
          i {
            // border: black 1px solid;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: fit-content;
          }
          span {
            // border: black 1px solid;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            color: $primary500;
          }
        }
      }
      span {
        color: $gray900;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      p {
        color: $gray900;

        font-size: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      button {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: $primary500;
        color: $white;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        width: 100%;
      }
      .full_inspection {
        // background-color: bisque;
        display: flex;
        flex-direction: column;
        gap: 12px;
        a {
          text-decoration: none;
          color: $primary500;
          text-align: center;

          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
    .CTA__added {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;
      // border: black 1px solid;
      width: 100%;
      span {
        color: $gray300;
      }
      b {
        color: $gray500;
      }
    }
  }
}
.testGAPI {
  border: black 1px solid;
  width: 100%;
  height: 400px;
}
