@import "../../../styles/_var";

.bigCard {
  width: 704px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  box-shadow: 0px 1px 2px rgba(28, 30, 34, 0.08),
    0px 8px 16px rgba(28, 30, 34, 0.08);
  border-radius: 8px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }

  .bigCard__image {
    width: 100%;
    height: 225px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      height: 250px;
      border-radius: 8px;
    }
    .image__badge {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 8px;
      gap: 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      background-color: $yellow500;
      border-radius: 0px 8px 8px 0px;
      width: fit-content;
      margin-top: 8px;
    }
  }
  .bigCard__img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .bigCard__img_loaded {
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: none;
  }

  .bigCard__information {
    width: 100%;
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    .information__top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      .information__name {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $gray900;
        display: flex;

        flex-direction: row;
        gap: 4px;
      }
      .information__state {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray700;
      }
      .information__description {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray900;
        height: 63px;
        overflow: hidden;
      }
    }
    .information__bottom {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      padding: 0px;
      gap: 6px;
      width: 100%;
      justify-content: space-between;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $gray900;
      }
    }
  }
}
.wrapper {
  position: relative;
}
.delBtn {
  position: absolute;
  top: 10px;
  right: 5px;
  background-color: red;
  border-radius: 8px;
  width: 70px;
  height: 25px;
}
