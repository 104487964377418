@import "./../../../styles/__var.scss";

.middleCTA {
  // border: rgb(249, 238, 238) 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 96px 108px;
  gap: 24px;
  background-color: $darkBG1;
  // height: 500px;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: 1200px) {
    display: flex;
    padding: 40px 32px;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .middleCTA__content {
    // border: rgb(241, 233, 233) 1px solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 200px;
    height: 148px;
    width: 808px;
    @media (max-width: 1200px) {
      width: auto;
      height: auto;
      gap: 32px;
    }
    @media (max-width: 450px) {
      flex-direction: column;
      button {
        width: 100%;
      }
    }
    .middleCTA__text {
      // border: rgb(188, 129, 129) 1px solid;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;

      .middleCTA__title {
        // border: rgb(177, 107, 107) 1px solid;
        font-family: "Inter Tight";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        color: $white;
        @media (max-width: 1200px) {
          font-size: 19px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 22.8px */
        }
      }
      .middleCTA__subtitle {
        // border: rgb(184, 114, 114) 1px solid;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $white;
        @media (max-width: 1200px) {
        }
      }
    }
  }
}
