@import "./../../../styles/__var.scss";

.reviews {
  height: 628px;
  background-color: $lightBG;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 96px 108px;
  gap: 48px;
  height: fit-content;
  .reviews__content {
    //  border: black 1px solid;
    //background-color: bisque;

    // width: 1224px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 96px 108px;
    gap: 48px;
    @media (max-width: 1200px) {
      padding: 40px 32px;
      width: 100%;
    }
    .reviews__header {
      // border: black 1px solid;
      display: flex;
      flex-direction: row;
      // align-items: baseline;
      justify-content: space-between;
      padding: 0px;
      gap: 12px;
      //  width: 1224px;
      width: 100%;

      h2 {
        //  width: 1156px;
        //border: black 1px solid;
        font-family: "Inter Tight";
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 115%;
        color: $gray900;
        margin: 0;
        @media (max-width: 1200px) {
          font-size: 30px;
        }
      }
      .reviews__navigation {
        //border: black 1px solid;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 16px;

        // width: 56px;
        // height: 20px;
        .arrow {
          // border: black 1px solid;
          //height: 20px;
          width: 20px;
          padding: 10px 0;
        }
      }
    }
    .reviews__corousel {
      // border: black 1px solid;
      // background-color: antiquewhite;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      //   .reviews__card {
      //     // border: black 1px solid;
      //     display: flex;
      //     flex-direction: column;
      //     align-items: flex-start;
      //     padding: 0px;

      //     width: 392px;
      //     height: 344px;

      //     background: $white;
      //     box-shadow: 0px 1px 2px rgba(28, 30, 34, 0.08),
      //       0px 8px 16px rgba(28, 30, 34, 0.08);
      //     border-radius: 8px;
      //   }
      //display: none;
      @media (max-width: 1200px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0 32px;
      }
      @media (max-width: 450px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
