@import "./../../../styles/__var.scss";

.profile {
  cursor: pointer;
  svg path {
    transition: 0.2s ease-in-out fill;
  }
}
@media (hover: hover) {
  .profile:hover {
    svg path {
      fill: $primary500;
    }
  }
}
