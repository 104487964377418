@import "./../../../styles/__var.scss";

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 108px;
  gap: 60px;
  background-color: $lightBG;
  @media (max-width: 1200px) {
    padding: 40px 32px;
  }
  a {
    color: $gray700;
    &:hover {
      text-decoration: none;
    }
  }
  cursor: default;
  .footer__content {
    //border: black 1px solid;
    width: 100%;
    .footer__top {
      //  width: 1224px;
      // min-width: 100%;
      // background-color: aquamarine;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px;
      // gap: 128px;
      @media (max-width: 1200px) {
        flex-wrap: wrap;
      }
      @media (max-width: 450px) {
        gap: 24px;
      }
      li {
        list-style: none;
        white-space: nowrap;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray700;
      }
      ul {
        // border: black 1px solid;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        .footer__title {
          color: $gray900;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          // gap: 12px;
          margin-bottom: 12px;
        }
      }
      .footer__first {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        // width: 288px;
        // height: 150px;
        @media (max-width: 1200px) {
          width: 100%;
          margin-bottom: 40px;
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $gray700;
          width: 288px;
          @media (max-width: 1200px) {
            width: 100%;
          }
        }
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 24px;

          //  background-color: rgb(137, 137, 137);
          //  background-color: red;
        }
      }
      .footer__second {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        //width: 184px;
        @media (max-width: 450px) {
          width: 100%;
        }
      }
      .footer__third {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        // width: 184px;
        .cursor__pointer {
          cursor: pointer;
        }
        @media (max-width: 450px) {
          width: 100%;
        }
      }
      .footer__fourth {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        //  width: 184px;
        @media (max-width: 450px) {
          width: 100%;
        }
      }
    }
    .footer__bottom {
      //width: 1224px;
      width: 100%;
      //background-color: aquamarine;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px;
      gap: 515px;
      // display: none;
      @media (max-width: 1200px) {
        gap: inherit;
        margin-top: 40px;
      }
      @media (max-width: 450px) {
        flex-direction: column;
        gap: 10px;
      }
      .footer__left {
        span {
          // background-color: aquamarine;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $gray500;
        }
      }
      .footer__right {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        span {
          // background-color: bisque;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $gray500;
        }
      }
    }
  }
}
