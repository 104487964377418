@import "./../../../styles/__var.scss";
.getAquote {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 120px;
  height: 36px;
  border-radius: 8px;
  border: none;
  background-color: $primary500;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  color: $white;
  transition: 0.2s background-color ease-in-out;
}
@media (hover: hover) {
  .getAquote {
    &:hover {
      background-color: $secondary500;
    }
  }
}

.getAquote_big {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  background-color: $primary500;

  height: 48px;
  border: none;
  border-radius: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $white;
  white-space: nowrap;
}
