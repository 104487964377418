@import "./../../../styles/__var.scss";

.email {
  display: flex;
  flex-direction: row;
  .email__logo {
    margin: 0;
    margin-right: 8px;
  }
  .email__data {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
  }
}
.noEmail {
  display: none;
}
