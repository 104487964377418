@import "./../../../styles/__var.scss";

.hero {
  height: 600px;
  margin-top: 90px;

  background-image: url("../../../assets/images/hero.webp");

  @media (max-width: 768px) {
    background-image: url("../../../assets/images/hero-tablet.webp");
    background-repeat: no-repeat;
    height: fit-content;
  }
  @media (max-width: 450px) {
    background-image: url("../../../assets/images/hero-mobile.webp");
    background-size: contain;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .hero__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    width: 600px;
    // border: black 1px solid;

    padding-left: $padding108;
    padding-top: 120px;
    @media (max-width: 1200px) {
      padding-left: 32px;
      padding-top: 66px;
      width: 50%;
      height: fit-content;
    }

    @media (max-width: 450px) {
      width: 100%;
      padding: 0;
      padding-top: 250px;
    }

    .content__title {
      //   border: black 1px solid;
      height: fit-content;
      @media (max-width: 450px) {
        padding: 16px;
      }
      h1 {
        font-family: "Inter Tight";
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        line-height: 115%;
        color: $gray900;
        margin: 0;
      }
      @media (max-width: 1200px) {
        h1 {
          /* Mobile/Title */

          font-size: 42px;
          font-style: normal;
          font-weight: 600;
          line-height: 115%; /* 48.3px */
          //  width: 50%;
        }
      }
    }
    .content__subtitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 32px;
      //  border: black 1px solid;
      color: black;
      @media (max-width: 1200px) {
        flex-direction: column;
        align-self: start;
        div {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
        }
      }

      @media (max-width: 450px) {
        margin: 0 auto;
        width: 90%;
        button {
          width: 100%;
          height: 48px;
        }
        // justify-content: center;
        div {
          width: 100%;
        }
      }
    }
  }
  .hero__benefits {
    position: relative;
    z-index: 10;
    background-color: $white;
    box-shadow: 0px 1px 2px rgba(28, 30, 34, 0.04),
      0px 12px 32px rgba(28, 30, 34, 0.08);
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    padding: 40px;
    gap: 40px;
    margin-left: $padding108;
    margin-right: $padding108;
    //margin-top: 125px;
    margin-top: 65px;

    @media (max-width: 1200px) {
      display: flex;
      width: 1200px;
      padding: 40px 32px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      margin: 0;
      margin-top: 65px;
      border-radius: 0;
      box-shadow: none;
      border-bottom: 1px solid $outline2;
      position: inherit;
      width: 100%;
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 16px;
    }
    .benefits__circut {
      background-color: $secondary500;

      border-radius: 100px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px;
      gap: 10px;
    }
    .benefits__content {
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 0px;
      gap: 8px;

      width: fit-content;
      h6 {
        color: $gray900;
        font-family: "Inter";
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
      }
      p {
        color: $gray900;
        margin: 0;
        font-family: "Inter";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .benefits__first {
      background-color: $white;
    }
    .benefits__second {
      background-color: $white;
    }
    .benefits__third {
      background-color: $white;
    }
  }
}
