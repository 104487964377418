@import "./../../../styles/__var.scss";
.bottomCTA {
  background-color: $darkBG1;
  // height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 96px 108px;
  gap: 10px;
  @media (max-width: 1200px) {
    padding: 40px 32px;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .bottomCTA__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    isolation: isolate;

    // width: 1224px;
    height: 316px;
    // border: white 1px solid;
    position: relative;
    @media (max-width: 1200px) {
      height: fit-content;
    }
    @media (max-width: 450px) {
      flex-direction: column;
    }

    .vl {
      // border-left: 6px solid green;
      border-left: 2px solid rgba(255, 255, 255, 0.15);
      height: 500px;
      height: 100%;
      position: absolute;
      left: 50%;
      margin-left: -1px;
      top: 0;
      @media (max-width: 450px) {
        display: none;
      }
    }

    .bottomCTA__header {
      //  background-color: burlywood;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      @media (max-width: 1200px) {
        flex-direction: row;
      }
      h5 {
        font-family: "Inter Tight";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        color: $white;
        margin: 0;
        @media (max-width: 1200px) {
          font-size: 19px;
        }
      }
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $white;
      margin: 0;
    }

    // .bottomCTA__footer {
    //   // background-color: chartreuse;
    //   border: white 1px solid;
    // }
    .bottomCTA__left {
      // border: white 1px solid;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px 104px;
      gap: 48px;

      // width: 600px;
      height: 316px;
      // background-color: brown;
      @media (max-width: 1200px) {
        padding: 0;
        width: 100%;
        gap: 16px;
        height: fit-content;
      }
      @media (max-width: 450px) {
        :last-child {
          width: 100%;
        }
      }
    }
    .bottomCTA__right {
      // border: white 1px solid;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px 104px;
      gap: 48px;

      // width: 600px;
      height: 316px;
      @media (max-width: 1200px) {
        padding: 0;
        width: 100%;
        gap: 16px;
        height: fit-content;
      }
      @media (max-width: 450px) {
        :last-child {
          width: 100%;
        }
      }
    }
  }
}
