@import "../../../styles/_var";

.resetAll {
  //  border: black 1px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px;
  gap: 2px;
  background: #f0f0f0;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: $gray500;
  cursor: pointer;
}
