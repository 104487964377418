@import "./../../../styles/__var.scss";
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9997;
  pointer-events: none;
  transition: background-color 0.3s ease-in-out;
}
.overlay.active {
  background-color: rgba(0, 0, 0, 0.5);
}
.sidebar_menu {
  width: 100vw;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transform: translateX(-100%);
  will-change: transform;
  transition: transform 0.5s ease-in-out;

  .sidebar__wrapper {
    background-color: $white;
    width: 100svw;
    height: 100svh;
    background-color: rgba(248, 247, 247, 0.978);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px;

    .topBlock {
      display: flex;
      flex-direction: column;
      gap: 20px;
      button {
        background-color: red;
        width: 30px;
        height: 30px;
        border-radius: 6px;
        border: $gray500 1px solid;
        background: none;
      }
      // svg {
      //   margin-left: 30px;
      // }
      .topBlock__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 50px;
      }
    }
    .bottomBlock {
      .bottomBlock__links {
        li {
          display: flex;
          flex-direction: row;
          padding: 10px 0;
          a span div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          span {
            text-wrap: nowrap;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 7px;
            text-decoration: none;
            color: $gray900;
            div {
              svg {
              }
            }
            button {
              background-color: $primary500;
              color: $white;
              border-radius: 6px;
              font-weight: 400;
            }
            a {
              text-decoration: none;
              color: $gray900;
            }
          }
        }
      }
      .bottomBlock__footer {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
    // .top {
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: space-between;
    //   padding: 16px;

    //   button {
    //     width: 50px;
    //     height: 50px;
    //     background-color: rgb(222, 220, 220);
    //     // background-color: #fff;
    //     border-radius: 15px;
    //   }
    // }
    // .links {
    //   display: flex;
    //   flex-direction: column;
    //   gap: 10px;
    //   padding: 30px;
    // }
    .navLink {
      list-style: none;
      color: $gray900;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-decoration: none;
    }
  }
}

.sidebar_menu.open {
  transform: translateX(0); /* Сдвиг влево, чтобы элемент появился */
}
