@import "./../../../styles/__var.scss";
//@import "rsuite/dist/rsuite.css";
@import "rsuite/dist/rsuite.css";

.filtersWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1200px) {
    width: 100%;
    position: absolute;
    transform: translateX(100%);
    // top: -100px;
    top: -75px;
    // top: 60px;
    right: 0;
    z-index: 9999;
    background-color: rgba(248, 247, 247, 0.95);
    transition: transform 0.5s ease-in-out;
  }

  @media (max-width: 1200px) {
    max-height: 100svh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  @media (max-width: 768px) {
    justify-content: flex-end;
  }

  .filters__closeBtn {
    display: none;
    @media (max-width: 1200px) {
      display: block;
      // background-color: red;
      border-radius: 8px;
      background: none;
      border: 1px $outline1 solid;
      width: 50px;
      height: 50px;
      margin: 10px;
      font-size: 30px;
      position: sticky;
      top: 15px;
      left: -10px;
      z-index: 999999;
    }
  }
  @media (max-width: 450px) {
    // display: block;
    // background-color: blue;
    // height: 100svh;
    // width: 20svw;
  }
}
.openFilters {
  transform: translateX(0%);
}
.closedFilters {
}

.filters {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  border: 1px solid $outline2;
  border-radius: 8px;
  width: 288px;
  // height: 1082px;
  height: fit-content;
  // height: 75vh;
  // position: sticky;
  // top: 100px;
  // right: 0;
  @media (max-width: 450px) {
    width: 80svw;
  }
  .filters__label {
    // width: 256px;
    width: 100%;
    // height: 20px;

    //  border: black 1px solid;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 82px;
    h6 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $gray900;
      margin: 0;
    }
    span {
      width: 46px;
      height: 16px;

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $gray500;
      cursor: pointer;
    }
  }

  .filters__check_list {
    //  background-color: red;
    list-style: none;
    // display: block;
    //  border: black 1px solid;
    padding: 0;
    width: 100%;
    height: 192px;
    //overflow: scroll;
    overflow-y: scroll;
    //  margin-top: 0;
    margin-top: 12px;
    margin-bottom: 0;

    // &:-webkit-scrollbar {
    //   width: 20px;
    // }
    // scrollbar-color: #6969dd #e0e0e0;
    // scrollbar-width: thin;

    // &:-webkit-scrollbar {
    //   width: 10px;
    //   height: 10px;
    // }
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 0px;
      gap: 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $gray900;
    }
  }

  .filters__brand {
    // border: black 1px solid;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    input {
      width: 92%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 8px 10px 12px;
      gap: 8px;
      border: 1px solid $outline1;
      border-radius: 8px;
    }
    select {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 8px 10px 12px;
      gap: 8px;
      border: 1px solid $outline1;
      border-radius: 8px;
    }
  }
  .filters__model {
    // border: black 1px solid;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    input {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 8px 10px 12px;
      gap: 8px;
      border: 1px solid $outline1;
      border-radius: 8px;
    }
    select {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 8px 10px 12px;
      gap: 8px;
      border: 1px solid $outline1;
      border-radius: 8px;
    }
  }
  .filters__vehicle_type {
    width: 100%;
    // border: black 1px solid;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    //  gap: 12px;
  }
  .filters__mileage {
    //border: black 1px solid;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    // input {
    //   border: red 1px solid !important;
    // }

    .miliage__slider {
      //background-color: red;
      width: 100%;
      margin-top: 10px;
      div {
        div {
          // &:hover &::before {
          //   box-shadow: #3c7d46;
          //   // border: 2px solid red;
          // }
          &::before {
            border: #3c7d46 3px solid;
            // box-shadow: #3c7d46;
          }
          div {
            background: #3c7d46;
            &::before {
            }
            &::after {
              border-top-color: #3c7d46;
            }
          }
        }
      }
    }
  }
  .filters__min_max {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    input {
      width: 124px;
      height: 40px;
      border: 1px solid $outline1;
      // border: red 1px solid;
      border-radius: 8px;
      //  padding: 10px 12px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // gap: 4px;
      padding-left: 10px;
    }
  }
  .filters__year {
    // border: black 1px solid;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    .test {
      border: black 1px solid;
      background-color: red;
    }
  }
  .filters__price {
    // border: black 1px solid;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    .price__slider {
      //background-color: red;
      width: 100%;
      margin-top: 10px;
      div {
        div {
          // &:hover &::before {
          //   box-shadow: #3c7d46;
          //   // border: 2px solid red;
          // }
          &::before {
            border: #3c7d46 3px solid;
            // box-shadow: #3c7d46;
          }
          div {
            background: #3c7d46;
            &::before {
            }
            &::after {
              border-top-color: #3c7d46;
            }
          }
        }
      }
    }
  }
  .filters__country {
    //border: black 1px solid;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    input {
      width: 92%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 8px 10px 12px;
      gap: 8px;
      border: 1px solid $outline1;
      border-radius: 8px;
    }
  }
  .filters__city {
    width: 100%;
    //border: black 1px solid;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }

  .checkbox {
    position: absolute;
    opacity: 0;

    // Text
    & + label {
      display: inline-block;
      position: relative;
      cursor: pointer;
      // line-height: 20px;
      padding-left: 30px;
      //  font-size: 18px;
      // font-family: sans-serif;
      // color: #666; // Label colour

      // Checkbox
      &:before {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background: transparent;
        width: 20px;
        height: 20px;

        box-shadow: inset 0 0 0 1px $outline1;

        border-radius: 4px;
        transition: 200ms ease-in-out all;
      }

      // Tick
      &:after {
        position: absolute;
        display: block;
        top: 3px;
        left: 7.5px;

        content: "";
        // width: 3px;
        // height: 9px;
        width: 4px;
        height: 9px;
        border-right: 2px solid transparent;
        border-bottom: 2px solid transparent;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;

        transform: rotate(45deg);
        // transform: rotate(240deg);
        transition: 200ms ease-in-out all;
      }
    }

    // Hover
    & + label:hover {
      color: #333;
    }

    // Focus
    & + label:focus {
      outline: none;
    }

    // Checked
    &:checked {
      & + label {
        &:before {
          background: white;
        }
        &:after {
          border-color: $primary500;
          // border: 2px solid $primary500;
        }
      }
    }

    // Disabled
    &:disabled {
      & + label {
        &:before {
          background: #f2f2f2; // Disabled colour
          box-shadow: none;
        }
        &:after {
          border-color: transparent;
        }
      }
    }

    // Disabled Checked
    &:checked:disabled {
      & + label {
        &:before {
          background: #f2f2f2;
          box-shadow: none;
        }
        &:after {
          border-color: #cecece;
        }
      }
    }
  }
  .form_checkbox {
    position: relative;
    display: block;
    //  margin: 0 0 15px;
    // margin: 0;
    label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
      align-items: center;
    }
    .block_white {
      background-color: $white;
      border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_black {
      background-color: black;
      // border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_silver {
      background-color: silver;
      //  border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_gray {
      background-color: gray;
      // border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_blue {
      background-color: blue;
      // border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_red {
      background-color: red;
      // border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_green {
      background-color: green;
      //  border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_brown {
      background-color: brown;
      //  border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_gold {
      background-color: goldenrod;
      //  border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_purple {
      background-color: purple;
      //  border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_orange {
      background-color: orange;
      //  border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_yellow {
      background-color: yellow;
      //  border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
    .block_pink {
      background-color: pink;
      // border: $gray500 1px solid;
      border-radius: 100%;
      width: 15px;
      height: 15px;
    }
  }

  .more__filters {
    // background-color: antiquewhite;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    transition: all 1s ease;
    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
    .filters__fuel_type {
      height: fit-content;
      // border: black 1px solid;
      .filters__check_list {
        //   border: black 1px solid;
        height: fit-content;
        overflow-y: hidden;
      }
    }
    .filters__transmission {
      .filters__check_list {
        //   border: black 1px solid;
        height: fit-content;
        overflow-y: hidden;
      }
    }
  }

  .alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  .rs-slider {
    width: 100%;
  }
}
