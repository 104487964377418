@import "./../../../styles/__var.scss";
.topBar {
  background-color: $darkBG1;

  padding: 10px $padding108;
  height: fit-content;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1440px;
  z-index: 90;
  @media (max-width: 1200px) {
    padding: 8px 32px;
    justify-content: space-between;
    align-items: center;
    .logos {
      display: none !important;
    }
  }

  @media (max-width: 1200px) {
  }
  .topBar_left {
    display: flex;
    flex-direction: row;
    gap: 16px;
    // border: black 1px solid;
    div {
      //  background-color: aqua;
      display: flex;
      flex-direction: row;
      gap: 16px;

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
    }
    @media (max-width: 1200px) {
      div {
        gap: 1px;
      }
    }
  }
  .topBar_right {
    display: flex;
    flex-direction: row;

    .map_logo {
      margin: 0;
      margin-right: 6px;
    }
    .shipTo {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.7);
    }

    .shipTo_container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: fit-content;
      .flag_canada {
        margin: 0 6px;
        width: 20px;
        height: 20px;
      }
      .countryName {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #ffffff;
        margin-right: 24px;
      }
    }
    .currency {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.7);
      margin-right: 6px;
    }
    .currencySelected {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #ffffff;
      margin-right: 24px;
    }
    .question_logo {
      margin: 0;
      margin-right: 6px;
    }
    .help {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      /* identical to box height, or 123% */
      cursor: pointer;

      color: rgba(255, 255, 255, 0.7);
    }
  }

  @media (max-width: 1200px) {
    .shipTo {
      display: none;
    }
    .map_logo {
      display: none;
    }
    .flag_canada {
      display: none;
    }
    .countryName {
      display: none;
    }
    .currency {
      display: none;
    }
    .currencySelected {
      display: none;
    }
  }
}
