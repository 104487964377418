@import "../../../styles/_var";

.megaCard {
  //  border: black 1px solid;
  box-shadow: 0px 1px 2px rgba(28, 30, 34, 0.08),
    0px 8px 16px rgba(28, 30, 34, 0.08);
  border-radius: 8px;
  width: 100%;
  //  width: 1000px;
  height: fit-content;
  // height: 600px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 8px;
  }

  .previewBigImage {
    width: 100%;
    height: 606px;

    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    position: relative;
    @media (max-width: 768px) {
      height: fit-content;
    }
    span {
      width: 100%;
    }
    .bigImg {
      width: 100%;
      height: auto;
      height: 100%;
      object-fit: cover;

      border-radius: 8px;
    }
  }
  .littleImages {
    overflow-x: scroll;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 14px;

    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray100;
      border-radius: 20px;
      border: 6px solid $white;
    }

    .littleImage {
      min-width: 140px;
      height: 90px;
      border-radius: 8px;
      //   background-color: blue;
    }
  }
  .information {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 30px;
    .topInformation {
      display: flex;
      flex-direction: row;
      gap: 5px;
      @media (max-width: 768px) {
        gap: 8px;
        h3 {
          font-size: 20px;
          text-wrap: nowrap;
        }
      }
    }

    // table {
    //   // background-color: red;

    //   td {
    //     //  border: black 1px solid;
    //     color: $gray900;
    //     font-family: Inter;
    //     font-size: 14px;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: 20px;
    //   }
    //   tr {
    //     //border: black 1px solid;
    //     height: 30px;
    //     padding-top: 20px;
    //     .tableTitle {
    //       font-weight: 600;
    //     }
    //   }
    // }
    table {
      // background-color: red;

      td {
        //  border: black 1px solid;
        color: $gray900;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        input {
          min-height: 26px;
          height: 20px;
          min-width: 50px;
          max-width: 200px;
          border-radius: 4px;
          color: black;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
        .checkBox {
          // width: 100%;
          margin-left: -12px;
          border: $red500 1px solid !important;
        }
      }
      tr {
        //border: black 1px solid;
        height: 30px;
        height: 40px;
        padding-top: 20px;
        @media (max-width: 768px) {
          // display: flex;
          // flex-direction: column;
        }
        .tableTitle {
          font-weight: 600;
        }
      }
    }
    .desktopTable {
      @media (max-width: 768px) {
        display: none;
      }
    }
    .mobileTable {
      display: none;
      @media (max-width: 768px) {
        display: block;
      }

      td {
        padding-right: 50px;
        @media (max-width: 768px) {
          padding-right: 20px;
        }
      }
    }
    .mainInformation {
      //  border: black 1px solid;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 25px;
      //  background-color: aliceblue;
      height: fit-content;
      width: fit-content;
      width: 100%;
      //   max-width: 100%;
      @media (max-width: 1200px) {
        justify-content: space-between;
        gap: 0;
      }
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .left__information {
        // background-color: rgb(0, 255, 98);
        display: flex;
        width: 50%;
        //  height: 400px;
        flex-direction: column;
        align-items: flex-start;
        @media (max-width: 1200px) {
          width: 48%;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
        div {
          display: flex;
          padding: 10px 0px;
          align-items: flex-start;
          gap: 12px;
          align-self: stretch;
          border-bottom: 1px solid $outline2;
          span {
            width: 200px;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $gray900;
          }
          span:first-child {
            font-weight: 600;
          }
        }
      }
      .right__information {
        // background-color: burlywood;
        display: flex;
        width: 50%;
        // height: 400px;
        flex-direction: column;
        align-items: flex-start;
        @media (max-width: 1200px) {
          width: 48%;
        }
        @media (max-width: 768px) {
          width: 100%;
        }

        div {
          display: flex;
          padding: 10px 0px;
          align-items: flex-start;
          gap: 12px;
          align-self: stretch;
          border-bottom: 1px solid $outline2;
          span {
            width: 140px;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $gray900;
            @media (max-width: 768px) {
              width: 200px;
            }
          }

          span:first-child {
            font-weight: 600;
          }
        }
      }
    }
    .price {
      color: var(--Primary-500, #3c7d46);
      /* Desktop/H4 */
      font-family: Inter;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 115%; /* 29.9px */
      @media (max-width: 768px) {
        font-size: 14px;
        padding: 6px;
        border-radius: 4px;
      }
    }
    // .description {
    //   // font-size: 16px;
    //   display: flex;
    //   flex-direction: column;

    //   gap: 20px;
    //   span {
    //     font-size: 16px;
    //   }
    // }

    .description {
      height: fit-content !important;
      // background-color: aqua;
      display: flex;
      flex-direction: column !important;
      justify-content: center;
      // align-items: center;
      label {
      }
      .descriptionBox {
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        //  align-items: center;
        textarea {
          resize: none;
          width: 100%;
          height: 100px;
          border: none;
          border: $gray100 1px solid;
          border-radius: 8px;
        }
        span {
          overflow-wrap: anywhere;
        }
      }
    }
    .priceBox {
      display: flex;
      flex-direction: row;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        gap: 8px;
      }
    }

    .added {
      display: flex;
      flex-direction: column;

      gap: 20px;
      span {
        font-size: 16px;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 8px;
      }
      button {
        display: flex;
        width: 263px;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        color: $white;

        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .visit {
        background-color: $secondary500;
      }
      .edit {
        background-color: $primary500;
      }
      .delete {
        background-color: $red500;
      }
    }
  }
}
