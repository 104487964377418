@import "./../../../styles/__var.scss";

.littleCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 288px;
  width: 99%;
  height: 312px;

  background: #ffffff;
  /* Cards 1 */
  box-shadow: 0px 1px 2px rgba(28, 30, 34, 0.08),
    0px 8px 16px rgba(28, 30, 34, 0.08);
  border-radius: 8px;
  @media (max-width: 450px) {
    box-shadow: none;
    // border: $gray500 1px solid;
    box-shadow: 0px 0px 2px rgba(28, 30, 34, 0.2);
    margin: 2px;
    height: fit-content;
  }
  .littleCard__previewImg {
    width: 288px;
    width: 100%;
    height: 180px;
    position: relative;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 450px) {
      height: 260px;
    }
    span {
      position: absolute;
      top: 10px;
      left: 10px;
      color: black;
      font-weight: 500;
      background-color: $yellow500;
      padding: 3px 7px;
      border-radius: 20px;
      font-size: 12px;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .littleCard__content {
    // background-color: aquamarine;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 12px;
    min-width: 288px;
    // width: 256px;
    width: 100%;
    height: 132px;
    .littleCard__top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 4px;

      // width: 256px;
      height: 68px;

      .littleCard__name {
        display: flex;
        flex-direction: row;
        gap: 4px;
        // border: black 1px solid;
        color: $gray900;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
      .littleCard__state {
        //  border: black 1px solid;
        display: flex;
        flex-direction: row;
        margin-top: 0;
        gap: 8px;
        list-style: none;
        color: $gray500;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
      .littleCard__location {
        // display: flex;
        // flex-direction: row;
        margin-top: 5px;
        gap: 8px;
        list-style: none;
        color: $gray700;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .littleCard__bottom {
      //  border: black 1px solid;
      display: flex;
      flex-direction: row;
      gap: 12px;
      width: 100%;
      justify-content: space-between;
      .littleCard__prices {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        .littleCard__newPrice {
          // border: black 1px solid;
          color: $gray900;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        .littleCard__oldPrice {
          // border: black 1px solid;
          text-decoration: line-through;
          color: $gray300;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-wrap: nowrap;
        }
      }
    }
  }
}
