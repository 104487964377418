@import "../../styles/_var";

.personalPage {
  width: 100%;
  height: fit-content;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  padding: 96px $padding108;
  margin-top: 20px;
  @media (max-width: 768px) {
    padding: 32px;
  }
  @media (max-width: 450px) {
    padding: 32px 16px;
    margin-top: 80px;
  }

  .header {
    h1 {
      font-size: 44px;
      font-family: Inter Tight;
      font-weight: 600;
      line-height: 115%;
      color: $gray900;
      margin: 0;
      text-align: left;
      width: 100%;
      margin-bottom: 40px;
    }

    p {
      color: var(--Gray-700, #484848);

      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      @media (max-width: 450px) {
        padding-bottom: 20px;
      }
    }
  }

  .wrapper {
    width: 390px;

    margin: 48px 0;
    @media (max-width: 768px) {
      width: 60%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }

    h1 {
      color: $gray900;
      font-family: Inter Tight;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 115%;
      margin: 0;
    }
  }

  .navLink {
    text-decoration: none;
  }
}
input {
  display: flex;
  padding: 14px 12px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid $outline1;
  color: $gray300;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  &:focus {
    outline: none;
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  position: relative;

  @media (max-width: 450px) {
    width: 100%;
  }
  .preview {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    height: 600px;
    position: relative;
    border: $gray100 1px solid;
    border-radius: 8px;
    overflow: hidden;
    @media (max-width: 450px) {
      height: 250px;
    }

    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      .carPreview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        width: auto;

        border: none;
        height: fit-content;

        h3 {
          font-weight: 300;
          font-size: 26px;
        }
        button {
          width: 50px;
          height: 50px;
          align-self: center;
          font-size: 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: none;
          border: $gray300 1px solid;
        }
      }
      input {
        width: 100%;
      }

      .preview__photo {
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: none;
        border-radius: 8px;

        width: 100%;

        margin: 0 auto;
        img {
          border-radius: 8px;
          width: 100%;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }
    .rechangePreviewBtn {
      padding: 5px;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px;

      border: $gray500 1px solid;
      background: none;
    }
  }
  .photos {
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: auto;
    border: $gray100 1px solid;
    border-radius: 8px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
    label {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    h3 {
      text-align: center;
      font-weight: 300;
      font-size: 22px;
      width: 100%;
    }
    .addPhotos {
      width: 50px;
      height: 50px;
      align-self: center;
      font-size: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: none;
      border: $gray300 1px solid;
      @media (max-width: 450px) {
        width: 35px;
        height: 35px;
        padding: 8px 4px;
      }
    }
    .preview__photos {
      padding: 0;
      border: none;
      height: fit-content;
      width: 100%;
      width: fit-content;
      display: flex;
      flex-direction: row;
      gap: 20px;
      overflow-x: scroll;
      align-items: center;

      cursor: grab;
      &:active {
        cursor: grabbing;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: $white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray100;
        border-radius: 20px;
        border: 6px solid $white;
      }

      .previewPhoto {
        width: 150px;
        height: 50px;
        min-width: 150px;
        height: fit-content;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: none;
        border-radius: 8px;
        position: relative;
        border: black 1px solid;

        img {
          border-radius: 8px;

          width: 150px;
          object-fit: contain;
        }

        .deletePhoto {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 20px;
          height: 20px;
          background: none;
          border: $gray900 1px solid;
          border-radius: 5px;

          padding: 0;
          &:hover {
            border: $red500 1px solid;
            background-color: $gray300;
          }
        }
      }
    }
  }
  .priceBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    .price {
      //border: black 1px solid;
      border-radius: 8px;
      input {
        // height: 40px;
        // margin-left: 20px;
        // margin-right: 20px;
        // color: black;
        margin-left: 10px;
        min-height: 26px;
        height: 20px;
        min-width: 50px;
        max-width: 120px;
        border-radius: 4px;
        color: black;
      }
    }
    .priceCurrency {
      display: block;
    }
  }

  table {
    // background-color: red;

    td {
      //  border: black 1px solid;
      color: $gray900;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      input {
        min-height: 26px;
        height: 20px;
        min-width: 50px;
        max-width: 200px;
        border-radius: 4px;
        color: black;
      }
      .checkBox {
        // width: 100%;
        margin-left: -12px;
        border: $red500 1px solid !important;
      }
    }
    tr {
      //border: black 1px solid;
      height: 30px;
      height: 40px;
      padding-top: 20px;
      @media (max-width: 450px) {
        // display: flex;
        // flex-direction: column;
      }
      .tableTitle {
        font-weight: 600;
      }
    }
  }
  .desktopTable {
    @media (max-width: 450px) {
      display: none;
    }
  }
  .mobileTable {
    display: none;
    @media (max-width: 450px) {
      display: block;
    }

    td {
      padding-right: 50px;
    }
  }

  button {
    background-color: $primary500;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 24px;
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: none;
      margin: 0;
      padding: 0;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  .button__load {
    pointer-events: none;
    cursor: not-allowed;
    padding: 0;
  }
}
.description {
  height: fit-content !important;
  // background-color: aqua;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  // align-items: center;
  label {
  }
  .descriptionBox {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    textarea {
      resize: none;
      width: 100%;
      height: 100px;
      border: none;
      border: $gray100 1px solid;
      border-radius: 8px;
    }
  }
}

.error {
  border: 1px solid red !important;
  border-radius: 8px !important;
  @media (max-width: 450px) {
  }
}

.popUp__errors {
  position: absolute;
  bottom: 355px;
  left: 50%;
  transform: translate(-50%);
  height: 100vh;
  width: 80%;
  z-index: 9995;
}
.hidden {
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  line-height: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
}
