@import "../../styles/_var";

.forgotPassword {
  //   background-color: red;
  min-height: 65vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;

  @media (max-width: 768px) {
    padding: 32px;
  }
  @media (max-width: 450px) {
    padding: 32px 16px;
  }
  .wrapper {
    // background-color: rgb(201, 201, 212);
    width: 390px;
    // height: 500px;
    margin: 48px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 768px) {
      width: 60%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
    h1 {
      color: $gray900;

      /* Desktop/H3 */
      font-family: Inter Tight;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 115%; /* 36.8px */
      margin: 0;
      //   border: black 1px solid;
    }

    span {
      color: $gray900;
      /* Desktop/Body Small */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */

      margin-bottom: 0;
    }
  }
  .form {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;

    .email {
      //   border: black 1px solid;
      display: flex;
      flex-direction: column;
      color: var(--gray-900, #1a1a1a);
      /* Desktop/Body Small */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      gap: 8px;
      margin-top: 10px;
      input {
        display: flex;
        padding: 14px 12px;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid $outline1;
        color: $gray300;
        /* Desktop/Body Small */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        &:focus {
          outline: none;
        }
      }
      .topBox {
        display: flex;
        flex-direction: row;
        .errorEmail {
          margin-left: 5px;
          color: $red500;
        }
      }
    }

    .resetBtn {
      background-color: $primary500;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      padding: 12px 24px;
      display: flex;

      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
    }
    .backToLogin {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      color: $primary500;
      text-align: center;
      /* Desktop/Body Small */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .navLink {
      text-decoration: none;
    }
  }
  .sentedEmail {
    //border: black 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      text-align: center;
      color: $gray900;
      /* Desktop/Body Medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    a {
      text-align: center;
      text-decoration: none;
      color: $primary500;
      /* Desktop/Body Medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}
