$white: #ffffff;

$darkBG1: #2c2926;
$darkBG2: #4a4745;
$lightBG: #f6f5f4;

$outline1: #d1d1d1;
$outline2: #e6e6e6;

$gray900: #1a1a1a;
$gray700: #484848;
$gray500: #767676;
$gray300: #a3a3a3;
$gray100: #a3a3a3;
$gray50: #a3a3a3;

$blue500: #0394fc;
$blue50: #e6f4ff;

$orange500: #dd7437;
$orange50: #fff1e8;

$yellow500: #f2ac29;
$yellow50: #fdf3df;

$red500: #c31c27;
$blue50: #f6dddf;

$primary500: #3c7d46;
$primary100: #d7ecda;
$primary50: #d7ecda;

$secondary500: #e49a49;
$secondary100: #ffedd3;
$secondary50: #fff7eb;

$tertiary500: #465668;
$tertiary100: #dbe1e6;
$tertiary50: #dbe1e6;

//Desktop

$padding108: 108px;
