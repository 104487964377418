@import "./../../../styles/__var.scss";

.details {
  //  background-color: burlywood;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $primary500;
  gap: 6px;
}
